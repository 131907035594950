import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { CandidateProvider } from './context/CandidateContext'
import { FilterProvider } from './context/FilterContext'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CandidateProvider>
        <FilterProvider>
          <App />
        </FilterProvider>
      </CandidateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
