import { Button, Dropdown } from 'react-bootstrap'
import './DropdownComponent.css'

interface IDropdropProps {
  showDropdown: boolean
  setShowDropdown: (cb: (showDropdown: boolean) => boolean) => void
  clearAllButton?: boolean
  clearAllButtonFunction?: (selectedJobRoles: string[]) => void
  dropdownButtonText: string | number
  disabled?: boolean[]
  children: React.ReactNode
}

export default function DropdownComponent(props: IDropdropProps) {
  const handleDropdownClose = () => {
    props.setShowDropdown((prevState) => !prevState)
  }
  const handleClearAll = () => {
    if (props.clearAllButtonFunction) {
      props.clearAllButtonFunction([])
    }
  }

  return (
    <Dropdown
      className="me-lg-4"
      autoClose="outside"
      onToggle={handleDropdownClose}
    >
      <Dropdown.Toggle
        id="dropdown-autoclose-outside"
        variant="outline-secondary"
        active={props.showDropdown}
      >
        {props.dropdownButtonText}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="p-3 SearchBar__Dropdown"
        show={props.showDropdown}
      >
        {props.children}
        <Dropdown.Divider />
        {props.clearAllButton && (
          <Button
            variant="outline-* text-decoration-underline"
            className="col-6 fw-bold"
            onClick={handleClearAll}
          >
            Clear all
          </Button>
        )}
        <Button
          variant="primary"
          className="col-6 fw-bold float-end"
          onClick={handleDropdownClose}
        >
          Close
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}
