import { useState, Fragment, useEffect } from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { getAutocompleteCity } from '../../services/JobService'
import './LocationSearch.css'
import { useFilterContext } from '../../context/FilterContext'
import { Option } from 'react-bootstrap-typeahead/types/types'

export default function LocationSearchBar() {
  const filterContext = useFilterContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [availableLocations, setAvailableLocations] = useState<Option[]>([])
  const [selectedItems, setSelectedItems] = useState<Option[]>(() => {
    console.log('here')
    return []
  })

  const handleSearch = (query: string) => {
    setIsLoading(true)
    getAutocompleteCity(query).then((res) => {
      const cities = res.data.data.map((item) => {
        return item.city
      })
      setAvailableLocations(cities)
      setIsLoading(false)
    })
  }

  const handleChange = (items: Option[]) => {
    setSelectedItems(items)
    filterContext.setCity(items[0] as string)
  }

  useEffect(() => {
    setSelectedItems((prev) => {
      if (filterContext.city == null) {
        return prev
      }
      if (prev == [filterContext.city]) {
        return prev
      } else {
        return [filterContext.city]
      }
    })
  }, [filterContext.city])

  return (
    <AsyncTypeahead
      className="LocationSearch__wrapper"
      inputProps={{ className: 'shadow-sm' }}
      filterBy={() => true}
      id="async-example"
      isLoading={isLoading}
      labelKey="city"
      minLength={3}
      onSearch={handleSearch}
      options={availableLocations}
      placeholder="Where do you want to work?"
      renderMenuItemChildren={(option, _props) => (
        <Fragment>
          <span>{option}</span>
        </Fragment>
      )}
      clearButton={true}
      selected={selectedItems}
      onChange={handleChange}
    />
  )
}
