import { ChangeEvent, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useFilterContext } from '../../context/FilterContext'

export default function QualificationFilter() {
  const filterContext = useFilterContext()
  const [checkedState, setCheckedState] = useState<Record<string, boolean>>({})
  const jobRolesDescriptions: Record<string, string> = {
    HHA: 'Home Health Aide',
    CNA: 'Certified Nursing Assistant',
    RN: 'Registered Nurse',
    LPN: 'Licensed Practical Nurse'
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const updatedCheckedState = { ...checkedState }
    updatedCheckedState[key] = e.target.checked
    setCheckedState(updatedCheckedState)

    const newSelectedRoles = filterContext.allJobRoles.filter(
      (value, _index) => {
        return updatedCheckedState[value]
      }
    )
    filterContext.setJobRoles(newSelectedRoles)
  }

  useEffect(() => {
    const updatedCheckedState = filterContext.allJobRoles.reduce(
      (prev: Record<string, boolean>, current: string) => {
        prev[current] = filterContext.jobRoles.indexOf(current) > -1
        return prev
      },
      {}
    )

    setCheckedState(updatedCheckedState)
  }, [filterContext.allJobRoles, filterContext.jobRoles])

  return (
    <Form>
      {filterContext.allJobRoles.map((role, index) => {
        return (
          <label className="row" key={`index-${index}`}>
            <div className="col-10">
              <p className="col-12  fs-5 mb-0">{role}</p>
              <p className="col-12 text-muted fs-6">
                {jobRolesDescriptions[role]}
              </p>
            </div>
            <Form.Check
              aria-label={`${role} checkbox`}
              name="group1"
              type="checkbox"
              className="col fs-3"
              onChange={(e) => handleChange(e, role)}
              checked={checkedState[role] === true}
              value={role}
            ></Form.Check>
          </label>
        )
      })}
    </Form>
  )
}
