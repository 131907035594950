import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useFilterContext } from '../../context/FilterContext'

export default function ShiftTagsFilter() {
  const [multiSelections, setMultiSelections] = useState(Array<string>())

  const options = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'morning',
    'afternoon',
    'evening',
    'night'
  ]

  const filterContext = useFilterContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const set = (items: any) => {
    filterContext.setShiftTags(items)
  }

  useEffect(() => {
    setMultiSelections(filterContext.shiftTags)
  }, [filterContext.shiftTags])

  return (
    <div>
      <Typeahead
        id="basic-typeahead-multiple"
        labelKey="name"
        multiple
        onChange={set}
        options={options.map((o) => capitalize(o))}
        placeholder="Pick your preferred shifts"
        selected={multiSelections}
      />
    </div>
  )
}
