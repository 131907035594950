import axios from 'axios'

export type PreferredShifts = Record<string, string[]>

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_JOBS_BACKEND,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_BACKEND_ACCESS_TOKEN}`
  }
})

interface ISingleJobResponse {
  data: ISingleJob
}

export interface ISingleJob {
  id: string
  externalJobId: string
  externalRequisitionId: string
  patientAge: string
  patientGender: string
  primaryLanguage: string
  patientDiagnosis: string
  patientStory: string
  skillsRequired: string
  lat: number
  lng: number
  url: string
  title: string
  city: string
  startOfCare?: string
  distance: number | null
  hourlyRateAmount: number
  jobRole: string

  mondayShifts: string[]
  tuesdayShifts: string[]
  wednesdayShifts: string[]
  thursdayShifts: string[]
  fridayShifts: string[]
  saturdayShifts: string[]
  sundayShifts: string[]
}

export function getJobDetails(jobId: string) {
  const fields: string[] = [
    'id',
    'externalJobId',
    'externalRequisitionId',
    'patientAge',
    'patientGender',
    'primaryLanguage',
    'patientDiagnosis',
    'patientStory',
    'skillsRequired',
    'lat',
    'lng',
    'url',
    'title',
    'city',
    'startOfCare',
    'distance',
    'hourlyRateAmount',
    'jobRole',
    'mondayShifts',
    'tuesdayShifts',
    'wednesdayShifts',
    'thursdayShifts',
    'fridayShifts',
    'saturdayShifts',
    'sundayShifts'
  ]
  return apiClient.get<ISingleJobResponse>(`v1/jobs/${jobId}`, {
    params: { fields: fields.join(',') }
  })
}

export interface IFilterSuggestion {
  zipcode?: string
  city?: string
  qualification?: string
  candidateId?: string
  email: string
  appliedJobIds: string[]
  radius: number
  preferredShifts: PreferredShifts
  shiftTags: string[]
}

interface IFilterSuggestionResponse {
  data: IFilterSuggestion
}

export function getFilterOptions(email: string) {
  return apiClient.get<IFilterSuggestionResponse>(
    `v1/jobs/search/filter/suggest/email/${email}`
  )
}

interface IJobSearchResponse {
  data: IPaginatedJob[]
  pagination: IPagination
}

interface IPagination {
  totalItems: number
  items: number
  page: number
  totalPages: number
}

export interface IPaginatedJob {
  id: string
  externalJobId: string
  lat: number
  lng: number
  city: string
  startOfCare?: string
  distance: number | null
  hourlyRateAmount: number
  jobRole: string

  mondayShifts: string[]
  tuesdayShifts: string[]
  wednesdayShifts: string[]
  thursdayShifts: string[]
  fridayShifts: string[]
  saturdayShifts: string[]
  sundayShifts: string[]
}

export function getJobsPaginated(
  city: string | null,
  qualification: string[],
  shifts: PreferredShifts,
  shiftTags: string[],
  radius: number | null,
  page = 1,
  perPage = 20,
  abortController?: AbortController
) {
  const fields: string[] = [
    'id',
    'externalJobId',
    'lat',
    'lng',
    'city',
    'startOfCare',
    'distance',
    'hourlyRateAmount',
    'jobRole',
    'mondayShifts',
    'tuesdayShifts',
    'wednesdayShifts',
    'thursdayShifts',
    'fridayShifts',
    'saturdayShifts',
    'sundayShifts'
  ]
  return apiClient.get<IJobSearchResponse>('v1/jobs/search', {
    signal: abortController?.signal,
    params: {
      city: city,
      qualification: qualification.length > 0 ? qualification.join(',') : null,
      radius: radius,
      perPage: perPage,
      page: page,
      fields: fields.join(','),
      shiftTags: shiftTags.join(','),
      monday: shifts.monday.length > 0 ? shifts.monday.join(',') : null,
      tuesday: shifts.tuesday.length > 0 ? shifts.tuesday.join(',') : null,
      wednesday:
        shifts.wednesday.length > 0 ? shifts.wednesday.join(',') : null,
      thursday: shifts.thursday.length > 0 ? shifts.thursday.join(',') : null,
      friday: shifts.friday.length > 0 ? shifts.friday.join(',') : null,
      saturday: shifts.saturday.length > 0 ? shifts.saturday.join(',') : null,
      sunday: shifts.sunday.length > 0 ? shifts.sunday.join(',') : null
    }
  })
}

interface IAutocompleteResponse {
  data: ILocationAutocompleted[]
}

export interface ILocationAutocompleted {
  city: string
}

export function getAutocompleteCity(partialCityName: string) {
  return apiClient.get<IAutocompleteResponse>(
    `v1/jobs/search/filter/autocomplete/city/${partialCityName}`
  )
}

export function applyToJob(candidateId: string, jobId: string) {
  return apiClient.post(`v1/jobs/apply`, {
    candidateId: candidateId,
    jobId: jobId
  })
}
