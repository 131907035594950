import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import ApplyNowButton from '../../components/ApplyNowButton/ApplyNowButton'
import JobAvailabilityComponent from '../../components/JobAvailabilityComponent/JobAvailabilityComponent'
import JobBasicInfo from '../../components/JobBasicInfo/JobBasicInfo'
import JobMapComponent from '../../components/JobMapComponent/JobMapComponent'
import { getJobDetails, ISingleJob } from '../../services/JobService'
import { useNavigate } from 'react-router-dom'
import EstimatedPayTable from '../../components/EstimatedPayTable/EstimatedPayTable'

export default function JobDetails() {
  const [jobDetails, setJobDetails] = useState<ISingleJob | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { jobId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getJobDetails(jobId ?? '')
      .then((response) => {
        setJobDetails(response.data.data)
        setLoading(false)
      })
      .catch((error) => console.log(error))
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  const shiftCountPerWeek = (): number => {
    if (jobDetails === null) {
      return 0
    } else {
      return (
        jobDetails.mondayShifts.length +
        jobDetails.tuesdayShifts.length +
        jobDetails.wednesdayShifts.length +
        jobDetails.thursdayShifts.length +
        jobDetails.fridayShifts.length +
        jobDetails.saturdayShifts.length +
        jobDetails.sundayShifts.length
      )
    }
  }

  const LoadingText = () => {
    return (
      <div className="row">
        <div className="col">
          <h5 className="text-center">LOADING...</h5>
        </div>
      </div>
    )
  }

  interface PropsWithJob {
    job: ISingleJob
  }

  const JobInfo = (props: PropsWithJob) => {
    const { job } = props
    return (
      <>
        <div className="col">
          <div className="row align-items-center mb-3">
            <div className="col-2 fs-1">
              <i className="bi bi-person"></i>
            </div>
            <div className="col-10">
              <span className="fs-5">Patient</span>
              <br />
              <span className="fs-5">
                {job.patientAge} {job.patientGender}
              </span>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-2 fs-1">
              <i className="bi bi-translate"></i>
            </div>
            <div className="col-10">
              <span className="fs-5">Primary language</span>
              <br />
              <span className="fs-5">{job.primaryLanguage}</span>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-2 fs-1">
              <i className="bi bi-clock"></i>
            </div>
            <div className="col-10">
              <span className="fs-5">Shifts</span>
              <br />
              <span className="fs-5">{shiftCountPerWeek()} per week</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="col">
          <p>
            <span className="text-start fw-bold">CaseID: </span>
            <span>{job.externalRequisitionId}</span>
          </p>
        </div>
        <div className="col">
          <p>
            <span className="text-start fw-bold">Diagnosis</span>
            <br />
            <span>{job.patientDiagnosis}</span>
          </p>
        </div>
        <div className="col">
          <p>
            <span className="text-start fw-bold">Patient details</span>
            <br />
            <span>{job.patientStory}</span>
          </p>
        </div>
        <div className="col">
          <p>
            <span className="text-start fw-bold">Required skills</span>
            <br />
            <span>{job.skillsRequired}</span>
          </p>
        </div>
      </>
    )
  }

  const JobDetails = (props: PropsWithJob) => {
    const { job } = props
    return (
      <div className="row mb-5">
        <div className="col-xs-12 col-sm-12">
          <div className="row">
            <div className="col mb-5">
              <JobMapComponent job={job} width="100%" height="25rem" />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="row">
            <div className="col-xs-12 col-lg-10 offset-lg-1">
              <JobBasicInfo job={job}></JobBasicInfo>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-10 offset-lg-1 mb-4">
              <EstimatedPayTable qualifications={job.jobRole} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-10 offset-lg-1">
              <span className="text-start fw-bold">Shifts schedule</span>
              <JobAvailabilityComponent job={job} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <div className="row d-none d-md-block mb-3">
            <div className="col-xs-12 col-lg-10 offset-lg-1 d-grid">
              <ApplyNowButton jobId={job.externalJobId} jobUrl={job.url} />
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <JobInfo job={job} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const TopBar = () => {
    return (
      <div
        className="row sticky-top pt-2 pb-2 d-flex align-items-center"
        style={{ backgroundColor: 'white' }}
      >
        <div className="col-1">
          <Button onClick={goBack} variant="link" style={{ color: 'black' }}>
            <i className="bi bi-chevron-left"></i>{' '}
          </Button>
        </div>
        <div className="col-11">
          <small className="text-muted">Case Details</small>
          <br />
          <h4>{jobDetails?.title}</h4>
        </div>
      </div>
    )
  }

  const BottomBar = () => {
    return (
      <div
        className="row fixed-bottom pb-2 pt-2 d-sm-none"
        style={{ backgroundColor: 'white' }}
      >
        <div className="col-10 col-xl-2 mx-auto mx-xl-0 ms-xl-auto align-self-end">
          <ApplyNowButton
            jobId={jobDetails?.externalJobId ?? ''}
            jobUrl={jobDetails?.url ?? ''}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-xs-12 col-lg-10">
          <TopBar />
          {loading && <LoadingText />}
          {!loading && jobDetails !== null && <JobDetails job={jobDetails} />}
        </div>
      </div>
      <BottomBar />
    </>
  )
}
