import { StaticGoogleMap, Marker } from 'react-static-google-map'
import { IPaginatedJob } from '../../services/JobService'

type Props = {
  job: IPaginatedJob
}

export default function JobMapStaticComponent(props: Props) {
  const { job } = props

  const jobLocation = { lat: job.lat, lng: job.lng }

  return (
    <StaticGoogleMap
      // as={(props: any) => <img {...props} style={{minHeight: "300px"}} />}
      className="img-fluid"
      size="400x400"
      scale="2"
      zoom="10"
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'missing-key'}
    >
      <Marker location={jobLocation} />
    </StaticGoogleMap>
  )
}
