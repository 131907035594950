// import { formatGender } from '../../utils/functions'
import { IPaginatedJob } from '../../services/JobService'
import JobAvailabilityComponentMini from '../JobAvailabilityComponent/JobAvailabilityComponentMini'
import JobBasicInfo from '../JobBasicInfo/JobBasicInfo'
// import JobMapComponent from '../JobMapComponent/JobMapComponent'
import JobMapStaticComponent from '../JobMapComponent/JobMapStaticComponent'

type Props = {
  job: IPaginatedJob
  hasApplied: boolean
}

const JobCard = ({ job, hasApplied }: Props) => {
  return (
    <div className="JobCard card shadow-sm h-100">
      <div className="card-img-top position-relative">
        {/* <JobMapComponent job={job} width="100%" height="20rem" /> */}
        <JobMapStaticComponent job={job} />
        {hasApplied && (
          <div className="px-3 py-1 bg-secondary text-white position-absolute bottom-0 start-50 translate-middle-x w-100">
            You have already applied for this job.
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <JobBasicInfo job={job} />
          </div>
          <div className="col-12">
            <JobAvailabilityComponentMini job={job} />
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <ul className="list-unstyled">
              <li>
                {job.patientAge}{' '}
                {formatGender(job.patientAge.split(' ')[0], job.patientGender)}
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default JobCard
