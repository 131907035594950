import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { applyToJob } from '../../services/JobService'
import { useCandidateContext } from '../../context/CandidateContext'

type Props = {
  jobId: string
  jobUrl: string
}

enum JobApplicationState {
  notApplied,
  alreadyApplied,
  applyInProgress,
  applySuccess,
  failedToApply
}

export default function ApplyNowButton(props: Props) {
  const candidateContext = useCandidateContext()

  const [jobApplicationState, setJobApplicationState] =
    useState<JobApplicationState>(
      candidateContext.checkifAlreadyApplied(props.jobId)
        ? JobApplicationState.alreadyApplied
        : JobApplicationState.notApplied
    )

  const applyButtonTapped = () => {
    const candidateId = candidateContext.candidateId
    if (candidateId) {
      if (jobApplicationState == JobApplicationState.alreadyApplied) {
        return
      }
      if (jobApplicationState == JobApplicationState.applyInProgress) {
        return
      }
      setJobApplicationState(JobApplicationState.applyInProgress)

      applyToJob(candidateId, props.jobId).then(
        (_res) => {
          candidateContext.addNewAppliedJobId(props.jobId)
          setJobApplicationState(JobApplicationState.applySuccess)
        },
        (err) => {
          if (err.response.status == 400) {
            if (err.response.data.message == 'alreadyApplied') {
              candidateContext.addNewAppliedJobId(props.jobId)
              setJobApplicationState(JobApplicationState.alreadyApplied)
              return
            }
          }
          setJobApplicationState(JobApplicationState.failedToApply)
          console.log(err)
        }
      )
    } else {
      openExternalUrl(props.jobUrl)
    }
  }

  const openExternalUrl = (url: string) => {
    window.open(url)
  }

  return (
    <Button
      className="float-end me-2"
      variant="primary"
      onClick={() => applyButtonTapped()}
    >
      {jobApplicationState == JobApplicationState.notApplied && 'Apply now'}
      {jobApplicationState == JobApplicationState.alreadyApplied && (
        <span>
          <i className="bi bi-check-all"></i>&nbsp; Already applied
        </span>
      )}
      {jobApplicationState == JobApplicationState.applySuccess && (
        <span>
          <i className="bi bi-check2"></i>&nbsp; You have applied!
        </span>
      )}
      {jobApplicationState == JobApplicationState.applyInProgress && (
        <span>
          <i className="bi bi bi-hourglass-split"></i>&nbsp; Applying...
        </span>
      )}
      {jobApplicationState == JobApplicationState.failedToApply && (
        <span>
          <i className="bi bi-exclamation-circle"></i>&nbsp; Something went
          wrong
        </span>
      )}
    </Button>
  )
}
