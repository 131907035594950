import queryString, { StringifyOptions, ParseOptions } from 'query-string'

export const formatDate = (date: string, longFormat = false) => {
  const suffixMap: Record<string, string> = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
  }
  const locale = 'en-US'
  const newDate = new Date(date)
  const dayOfMonth = newDate.getDate()
  const month = newDate.toLocaleString(locale, { month: 'long' })
  const shortMonthFormat = newDate.toLocaleString(locale, { month: 'short' })
  const pluralRule = new Intl.PluralRules(locale, { type: 'ordinal' })
  const ordinal = pluralRule.select(dayOfMonth)

  return (
    (longFormat ? month : shortMonthFormat) +
    ' ' +
    dayOfMonth +
    suffixMap[ordinal]
  )
}

export const metersToMiles = (meters: number | null): string => {
  if (meters == null) {
    return ''
  }
  const miles = Math.round((meters / 1000) * 0.621371)
  if (miles == 0) {
    return 'near you'
  } else {
    return `${miles} miles`
  }
}

export const formatNumberToPricePerHour = (
  amount: number,
  _currency = 'USD'
) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return `${dollarUS.format(+amount)}/h`
}

export const getEmailFromURL = (url: string) => {
  const stringArray = url.split('/')
  const email = stringArray[stringArray.length - 1].replace('%40', '@')
  return email
}

export const encodeFilter = (
  city: string | null,
  radius: number,
  jobRoles: string[],
  shiftTags: string[]
): string => {
  const options = {
    skipNull: true,
    arrayFormat: 'comma' as StringifyOptions['arrayFormat']
  }

  return queryString.stringify(
    {
      city: city,
      radius: radius,
      roles: jobRoles.length > 0 ? jobRoles.join(',') : null,
      shiftTags: shiftTags.length > 0 ? shiftTags.join(',') : null
    },
    options
  )
}

type DecodedFilter = [
  city: string | null,
  radius: number,
  jobRoles: string[],
  shiftTags: string[]
]

export const decodeFilter = (filter: string): DecodedFilter => {
  const options = {
    parseNumbers: true,
    arrayFormat: 'comma' as ParseOptions['arrayFormat']
  }
  const parsedFilter = queryString.parse(filter, options)
  let city: string | null
  let radius = 25
  const jobRoles: string[] = []
  const shiftTags: string[] = []

  if (Array.isArray(parsedFilter.city)) {
    city = parsedFilter.city[0]
  } else {
    city = parsedFilter.city
  }

  if (Array.isArray(parsedFilter.radius)) {
    const radiusFromFilter = parsedFilter.radius[0]
    if (radiusFromFilter != null) {
      radius = parseInt(radiusFromFilter)
    }
  } else {
    const radiusFromFilter = parsedFilter.radius
    if (radiusFromFilter != null) {
      radius = parseInt(radiusFromFilter)
    }
  }

  if (Array.isArray(parsedFilter.roles)) {
    parsedFilter.roles.forEach((r) => {
      if (r !== null) {
        jobRoles.push(r)
      }
    })
  }

  if (Array.isArray(parsedFilter.shiftTags)) {
    parsedFilter.shiftTags.forEach((s) => {
      if (s !== null) {
        shiftTags.push(s)
      }
    })
  }

  return [city, radius, jobRoles, shiftTags]
}
