import ReactLoading from 'react-loading'
import './Preloader.css'

export default function Preloader() {
  return (
    <div className="row">
      <div className="d-flex justify-content-center">
        <ReactLoading
          type={'spinningBubbles'}
          color={'#ff0e5b'}
          height={100}
          width={100}
        />
      </div>
    </div>
  )
}
