import { ISingleJob } from '../../services/JobService'

type Props = {
  job: ISingleJob
}

export default function JobAvailabilityComponent(props: Props) {
  const { job } = props

  const daySegments = ['Morning', 'Afternoon', 'Evening', 'Night']
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
  const dayAlias: Record<string, string> = {
    Monday: 'M',
    Tuesday: 'Tu',
    Wednesday: 'W',
    Thursday: 'Th',
    Friday: 'F',
    Saturday: 'Sa',
    Sunday: 'Su'
  }

  const HorizontalTable = () => {
    return (
      <div className="table-responsive-sm ">
        <table className="JobAvailabilityComponent table table-borderless table-sm text-center">
          <thead>
            <tr>
              <td className="col-1"></td>
              {days.map((day, idx) => {
                return (
                  <td key={idx} className="col-1">
                    {dayAlias[day]}
                  </td>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {daySegments.map((segment, idxOutter) => {
              return (
                <tr key={idxOutter}>
                  <td>{segment}</td>
                  {days.map((day, idxInner) => {
                    const dayProperty = `${day.toLowerCase()}Shifts`
                    const dailyShifts = job[
                      dayProperty as keyof ISingleJob
                    ] as string[]
                    const isActive =
                      dailyShifts.indexOf(segment.toLowerCase()) > -1
                    return (
                      <td
                        key={idxInner}
                        className={isActive ? 'bg-blue' : ''}
                        style={{ border: '2px solid #012553' }}
                      ></td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <>
      <HorizontalTable />
    </>
  )
}
