import './App.css'
import 'typeface-rajdhani'
import './sws-bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Routes } from 'react-router-dom'
import JobDetails from './views/JobDetails/JobDetails'
import Offers from './views/Offers/Offers'
import JobsList from './views/JobsList/JobsList'

function App() {
  return (
    <Routes>
      <Route path="/" element={<JobsList />} />
      <Route path="/offers/:email" element={<Offers />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/job/:jobId" element={<JobDetails />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There&rsquo;s nothing here!</p>
          </main>
        }
      />
    </Routes>
  )
}

export default App
