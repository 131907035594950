import { useReducer } from 'react'
import { Button } from 'react-bootstrap'
import ModalComponent from '../Modal/ModalComponent'
import { useFilterContext } from '../../context/FilterContext'
import QualificationFilter from '../QualificationFilter/QualificationFilter'
import RangeFilter from '../RangeFilter/RangeFilter'
import LocationSearchBar from '../LocationSearchBar/LocationSearchBar'
import DropdownComponent from '../DropdownComponent/DropdownComponent'
import './SearchBar.scss'
import ShiftTagsFilter from '../ShiftTagsFilter/ShiftTagsFilter'

interface IFilterButtonsProps {
  className: string
}

enum DropdownReducerAction {
  toggleQualificationsModal = 'toggleQualificationsModal',
  toggleQualifications = 'toggleQualifications',
  toggleRangeModal = 'toggleRangeModal',
  toggleRange = 'toggleRange',
  toggleShiftsModal = 'toggleShiftsModal',
  toggleShifts = 'toggleShifts'
}

interface IInitialDropdownState {
  showQualificationsModal: boolean
  showQualifications: boolean
  showRangeModal: boolean
  showRange: boolean
  showShiftsModal: boolean
  showShifts: boolean
}

interface IDropdownReducerAction {
  type: DropdownReducerAction
}

export default function SearchBar() {
  const filterContext = useFilterContext()

  const initialDropdownState = {
    showQualificationsModal: false,
    showQualifications: false,
    showRangeModal: false,
    showRange: false,
    showShiftsModal: false,
    showShifts: false
  }

  const dropdownReducer = (
    state: IInitialDropdownState,
    action: IDropdownReducerAction
  ) => {
    const stateCopy = { ...state }
    Object.keys(state).forEach(
      (key) => (stateCopy[key as keyof IInitialDropdownState] = false)
    )

    switch (action.type) {
      case DropdownReducerAction.toggleQualificationsModal:
        stateCopy.showQualificationsModal = !state.showQualificationsModal
        return stateCopy
      case DropdownReducerAction.toggleQualifications:
        stateCopy.showQualifications = !state.showQualifications
        return stateCopy
      case DropdownReducerAction.toggleRangeModal:
        stateCopy.showRangeModal = !state.showRangeModal
        return stateCopy
      case DropdownReducerAction.toggleRange:
        stateCopy.showRange = !state.showRange
        return stateCopy
      case DropdownReducerAction.toggleShiftsModal:
        stateCopy.showShiftsModal = !state.showShiftsModal
        return stateCopy
      case DropdownReducerAction.toggleShifts:
        stateCopy.showShifts = !state.showShifts
        return stateCopy
      default:
        return state
    }
  }

  const [dropdownState, dropdownDispatch] = useReducer(
    dropdownReducer,
    initialDropdownState
  )

  const handleShowQualificationsModal = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleQualificationsModal
    })
  }

  const handleShowQualifications = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleQualifications
    })
  }

  const handleShowRangeModal = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleRangeModal
    })
  }

  const handleShowRange = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleRange
    })
  }

  const handleShowShiftsModal = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleShiftsModal
    })
  }

  const handleShowShifts = () => {
    dropdownDispatch({
      type: DropdownReducerAction.toggleShifts
    })
  }

  const FilterButtons = () => {
    return (
      <div className="table-responsive d-sm-block d-md-none">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <Button
                  variant="outline-secondary"
                  className="text-nowrap col-11"
                  active={dropdownState.showQualificationsModal}
                  onClick={handleShowQualificationsModal}
                >
                  {filterContext.returnHighestJobRole()}
                </Button>
              </td>
              <td>
                <Button
                  variant="outline-secondary"
                  className="text-nowrap col-11"
                  active={dropdownState.showRangeModal}
                  onClick={handleShowRangeModal}
                >
                  {filterContext.radius
                    ? `${filterContext.radius} miles`
                    : 'Range'}
                </Button>
              </td>
              <td>
                <Button
                  variant="outline-secondary"
                  className="text-nowrap col-11"
                  active={dropdownState.showShiftsModal}
                  onClick={handleShowShiftsModal}
                >
                  Preferred shifts
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const clearJobRoles = () => {
    filterContext.setJobRoles([])
  }

  const FilterDropdownButtons = (props: IFilterButtonsProps) => {
    return (
      <div className={props.className}>
        <DropdownComponent
          showDropdown={dropdownState.showQualifications}
          setShowDropdown={handleShowQualifications}
          clearAllButton={true}
          clearAllButtonFunction={clearJobRoles}
          dropdownButtonText={filterContext.returnHighestJobRole()}
        >
          <QualificationFilter />
        </DropdownComponent>
        <DropdownComponent
          showDropdown={dropdownState.showRange}
          setShowDropdown={handleShowRange}
          dropdownButtonText={
            filterContext.radius ? `${filterContext.radius} miles` : 'Range'
          }
        >
          <RangeFilter />
        </DropdownComponent>
        <DropdownComponent
          showDropdown={dropdownState.showShifts}
          setShowDropdown={handleShowShifts}
          dropdownButtonText="Preferred shifts"
        >
          <ShiftTagsFilter />
        </DropdownComponent>
      </div>
    )
  }

  return (
    <div className="SearchBar mt-2 mb-2">
      <div className="row mb-2">
        <div className="d-grid SearchBar__search my-2">
          <LocationSearchBar />
        </div>
        <div className="col-12">
          <FilterButtons />
        </div>
        <FilterDropdownButtons className="d-none d-lg-flex justify-content-start" />
        <ModalComponent
          showModal={dropdownState.showQualificationsModal}
          setShowModal={handleShowQualificationsModal}
          clearAllButton={true}
          clearAllButtonFunction={clearJobRoles}
          modalTitle="Qualifications"
        >
          <QualificationFilter />
        </ModalComponent>
        <ModalComponent
          showModal={dropdownState.showRangeModal}
          setShowModal={handleShowRangeModal}
          modalTitle="Range"
        >
          <RangeFilter />
        </ModalComponent>
        <ModalComponent
          showModal={dropdownState.showShiftsModal}
          setShowModal={handleShowShiftsModal}
          modalTitle="Shifts"
        >
          <ShiftTagsFilter />
        </ModalComponent>
      </div>
    </div>
    /* <ModalComponent
        isVisible={showQualifications}
        setIsVisible={setShowQualifications}
        title={'Qualifications'}
        body={jobRoles}
        setStateValues={setSelectedJobRoles}
      />
      <ModalComponent
        isVisible={showRate}
        setIsVisible={setShowRate}
        title={'Rate'}
        slider={hourlyRates}
        setStateValues={setSelectedHourlyRates}
      />
      <ModalComponent
        isVisible={showFilter}
        setIsVisible={setShowFilter}
        title={'Filter'}
        showTitles={true}
        body={jobRoles}
        slider={hourlyRates}
        setStateValues={setSelectedHourlyRates}
      /> */
  )
}
