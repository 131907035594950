import { formatNumberToPricePerHour } from '../../utils/functions'

type PayType = {
  regularPayRate: number
  bonus: number
  tenure: number
  effectivePayRate: number
}

export default function EstimatedPayTable(props: { qualifications: string }) {
  const estimatedPayTable: Record<string, PayType> = {
    HHA: {
      regularPayRate: 14.0,
      bonus: 0.69,
      tenure: 0.4,
      effectivePayRate: 15.09
    },
    CNA: {
      regularPayRate: 16.5,
      bonus: 0.81,
      tenure: 0.4,
      effectivePayRate: 17.71
    },
    LPN: {
      regularPayRate: 28.0,
      bonus: 1.37,
      tenure: 0.4,
      effectivePayRate: 29.77
    },
    RN: {
      regularPayRate: 33.0,
      bonus: 1.62,
      tenure: 0.4,
      effectivePayRate: 35.02
    }
  }

  const payObjectKey = estimatedPayTable[props.qualifications.toUpperCase()]

  return (
    <div>
      <p className="my-1">
        <span className="text-start fw-bold">
          Effective Pay Rate Paid Breakdown
        </span>
      </p>

      <ul className="ps-0">
        <div className="border-bottom border-dark border-1 mb-2 pb-2">
          <li className=" d-flex justify-content-between">
            <span>Regular Pay Rate</span>
            {formatNumberToPricePerHour(payObjectKey.regularPayRate)}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Bonus</span>
            {formatNumberToPricePerHour(payObjectKey.bonus)}
          </li>
          <li className=" d-flex justify-content-between">
            <span>Tenure</span>
            {formatNumberToPricePerHour(payObjectKey.tenure)}
          </li>
        </div>
        <li className=" d-flex justify-content-between fw-bold">
          <span>Effective Pay Rate Paid</span>{' '}
          {formatNumberToPricePerHour(payObjectKey.effectivePayRate)}
        </li>
      </ul>
    </div>
  )
}
