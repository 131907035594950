import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api'
import { IPaginatedJob, ISingleJob } from '../../services/JobService'

type Props = {
  job: IPaginatedJob | ISingleJob
  width?: string
  height?: string
}
export default function JobMapComponent(props: Props) {
  const { job, width, height } = props
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'key-not-set'
  })

  const defaultWidth = '400px'
  const defaultHeight = '400px'

  const mapSize = {
    width: width ?? defaultWidth,
    height: height ?? defaultHeight
  }

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.4,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.25,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 2500,
    zIndex: 1
  }

  const jobLocation = { lat: job.lat, lng: job.lng }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapSize}
          center={jobLocation}
          clickableIcons={false}
          zoom={10}
          // options={{
          //   disableDefaultUI: true,
          //   disableDoubleClickZoom: true,
          //   draggable: false,
          //   draggableCursor: null,
          //   draggingCursor: null
          // }}
          // onLoad={onLoad}
          // onUnmount={onUnmount}
        >
          {/* <Marker
          position={jobLocation}
        /> */}
          <Circle
            // required
            center={jobLocation}
            // required
            options={options}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  )
}
