import { ChangeEvent } from 'react'
import { Form } from 'react-bootstrap'
import { useFilterContext } from '../../context/FilterContext'

const RangeFilter = () => {
  const filterContext = useFilterContext()
  const radiusLabels: Record<number, string> = {
    10: 'around 15 min drive',
    15: 'around 20 min drive',
    25: 'around 35 min drive',
    50: 'around 60 min drive'
  }
  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    radius: number
  ) => {
    filterContext.setRadius(radius)
  }
  return (
    <Form>
      {filterContext.allRadiuses.map((radius, index) => {
        return (
          <label className="row" key={`index-${index}`}>
            <div className="col-10">
              <p className="col-12  fs-5 mb-0">{`${radius} miles`}</p>
              <p className="col-12 text-muted fs-6">{radiusLabels[radius]}</p>
            </div>
            <Form.Check
              aria-label={`${radius} checkbox`}
              name="group1"
              type="radio"
              className="col fs-3"
              checked={filterContext.radius == radius}
              onChange={(e) => handleChange(e, radius)}
              value={radius}
            ></Form.Check>
          </label>
        )
      })}
    </Form>
  )
}

export default RangeFilter
