import { IPaginatedJob, ISingleJob } from '../../services/JobService'
import {
  formatDate,
  formatNumberToPricePerHour,
  metersToMiles
} from '../../utils/functions'

type Props = {
  job: IPaginatedJob | ISingleJob
}

export default function JobBasicInfo(props: Props) {
  const { job } = props
  return (
    <div className="row">
      <div className="col">
        <p className="text-start">
          <span>{job.city}</span>
          <br />
          <span>
            Start{' '}
            {job.startOfCare === undefined
              ? 'unknown'
              : formatDate(job.startOfCare, true)}
          </span>
          <br />
          <span>{metersToMiles(job.distance)}</span>
        </p>
      </div>
      <div className="col">
        <p className="text-end">
          <span className="fs-3">
            {formatNumberToPricePerHour(job.hourlyRateAmount)}
          </span>
          <br />
          <span>{job.jobRole}</span>
        </p>
      </div>
    </div>
  )
}
