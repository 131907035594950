import { Button, CloseButton, Modal } from 'react-bootstrap'

interface IModalProps {
  showModal: boolean
  setShowModal: (cd: (showModal: boolean) => boolean) => void
  clearAllButton?: boolean
  clearAllButtonFunction?: (selectedJobRoles: string[]) => void
  modalTitle: string
  modalSize?: 'sm' | 'lg' | 'xl' | undefined
  children: React.ReactNode
}

export default function ModalComponent(props: IModalProps) {
  const handleModalClose = () => {
    props.setShowModal((prevState) => !prevState)
  }
  const handleClearAll = () => {
    if (props.clearAllButtonFunction) {
      props.clearAllButtonFunction([])
    }
  }
  return (
    <Modal
      size={props.modalSize}
      show={props.showModal}
      onHide={handleModalClose}
    >
      <Modal.Header className="pb-1">
        <CloseButton className="col-1" onClick={handleModalClose} />
        <h5 className="col text-center">{props.modalTitle}</h5>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        {props.clearAllButton && (
          <Button
            variant="outline-* text-decoration-underline"
            className="col-5 fw-bold"
            onClick={handleClearAll}
          >
            Clear all
          </Button>
        )}
        <Button
          variant="primary"
          className="col-6 fw-bold "
          onClick={handleModalClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
